<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Card</Heading>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Example</Heading>
							<Paragraph>Cards can have multiple cardSections, that can contain almost any components.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Card>
								<CardSection collapse>
									<Images height="200px" width="100%" image="theme-ds/cards.jpg" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="1" align="left">
										<Heading size="5">Card Title</Heading>
										<Paragraph>Some quick example text to build on the card title and make up the bulk of the card's content.</Paragraph>
										<Button color="secondary" label="Button" />
									</Stack>
								</CardSection>
							</Card>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Colors</Heading>
							<Paragraph>
								<Theme ar dl ed sd wm ds>There are eight color variations for button colors that can be applied using the color prop.</Theme>
								<Theme hv>There are six color variations for button colors that can be applied using the color prop.</Theme>
							</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="2">
							<Stack width="100%">
								<Stage>
									<Card color="primary" size="xsmall" margin="none">
										<CardSection>
											<Heading size="5" margin="none" whiteout>Primary</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="soft-primary" size="xsmall" margin="none">
										<CardSection>
											<Theme ar dl ed sd ds><Heading size="5" margin="none" >Soft Primary</Heading></Theme>
											<Theme hv wm><Heading size="5" margin="none" whiteout>Soft Primary</Heading></Theme>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="secondary" size="xsmall" margin="none">
										<CardSection>
											<Theme sd><Heading size="5" margin="none" >Secondary</Heading></Theme>
											<Theme ar dl ed hv wm ds><Heading size="5" margin="none" whiteout>Secondary</Heading></Theme>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="soft-secondary" size="xsmall" margin="none">
										<CardSection>
											<Theme sd ds ed><Heading size="5" margin="none">Soft Secondary</Heading></Theme>
											<Theme ar dl hv wm><Heading size="5" margin="none" whiteout>Soft Secondary</Heading></Theme>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Theme ar dl ed sd wm ds>
									<Stage>
										<Card color="tertiary" size="xsmall" margin="none">
											<CardSection>
												<Theme wm><Heading size="5" margin="none" >Tertiary</Heading></Theme>
												<Theme ar dl ed hv sd ds><Heading size="5" margin="none" whiteout>Tertiary</Heading></Theme>
											</CardSection>
										</Card>
									</Stage>
								</Theme>
							</Stack>
							<Stack width="100%">
								<Theme ar dl ed sd wm ds>
									<Stage>
										<Card color="soft-tertiary" size="xsmall" margin="none">
											<CardSection>
												<Theme ed sd wm ds><Heading size="5" margin="none">Soft tertiary</Heading></Theme>
												<Theme ar dl><Heading size="5" margin="none" whiteout>Soft tertiary</Heading></Theme>
											</CardSection>
										</Card>
									</Stage>
								</Theme>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="dark" size="xsmall" margin="none">
										<CardSection>
											<Heading size="5" margin="none" whiteout>Dark</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="light" size="xsmall" margin="none">
										<CardSection>
											<Heading size="5" margin="none">Light</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Sizes</Heading>
							<Paragraph>There are five size variations for cards that can be set using the size prop.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" width="100%" space="2" align="left">
							<Stack width="100%">
								<Stage>
									<Card color="light" size="xsmall" margin="none">
										<CardSection>
											<Heading size="5" margin="none">XSmall</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="light" size="small" margin="none">
										<CardSection>
											<Heading size="5" margin="none">Small</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="light" margin="none">
										<CardSection>
											<Heading size="5" margin="none">Default</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="light" size="large" margin="none">
										<CardSection>
											<Heading size="5" margin="none">Large</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
							<Stack width="100%">
								<Stage>
									<Card color="light" size="xlarge" margin="none">
										<CardSection>
											<Heading size="5" margin="none">Xlarge</Heading>
										</CardSection>
									</Card>
								</Stage>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Overlap</Heading>
							<Paragraph>To overlap two cards, apply prop overlapped to the first card, and prop overlap to the second card. This will add the styles needed for both cards.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Theme ar dl ed hv wm ds>
							<Stage>
								<Card color="light" overlapped>
									<CardSection>
										<Heading size="5" margin="none">Overlapped</Heading>
									</CardSection>
								</Card>
								<Card color="primary" overlap>
									<CardSection>
										<Heading size="5" margin="none">Overlap</Heading>
									</CardSection>
								</Card>
							</Stage>
						</Theme>
						<Theme sd>
							<Stage>
								<Card color="light" overlapped>
									<CardSection>
										<Heading size="5" margin="none">Overlapped</Heading>
										<br/><br/><br/>
									</CardSection>
								</Card>
								<Card color="secondary" overlap>
									<CardSection>
										<Heading size="5" margin="none">Overlap</Heading>
										<br/><br/><br/><br/><br/>
									</CardSection>
								</Card>
							</Stage>
						</Theme>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Card Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										xsmall<br />
										small<br />
										large<br />
										xlarge<br />
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the padding size of the card sectiongs inside the card component.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">color</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										primary<br />
										secondary<br />
										tertiary<br />
										soft-primary<br />
										soft-secondary<br />
										soft-tertiary<br />
										dark<br />
										light
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the background color of the card.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">type</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">base</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Removes all styles and padding.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">margin</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">none</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Removes the default margin bottom.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">image</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the background image of the card.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">fill</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Determines if the card will fill to the height of the container it is in.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">overlap</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Overlaps the element above the card.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">overlapped</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Adds padding to the card bottom for an item below that is overlapping.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Card Section Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">collapse</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Removes all padding from card section.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		this.stageTheme(this.theme);
  },
}
</script>

<style lang="scss" scoped>
</style>
